import React from "react";
import RDropZone from "react-dropzone";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import { fromEvent } from "file-selector";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Form from "react-bootstrap/Form";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import {
  computeChecksumMd5,
  getFilename,
  getParentDir,
  isImage,
  isVideo,
  isZip,
  isCSV,
} from "../../utilities/file";
import { getHours, getMinutes, getSeconds } from "../../utilities/time";
import {
  getPreSignedUrl,
  uploadFileToS3,
  uploadVideoToAssetManager,
  stitchVideosInAWS,
} from "../../utilities/api";
import "./index.css";
import { ListGroup, Badge } from "react-bootstrap";

import JSZip from "jszip";

const DragHandleItem = sortableHandle(({ children }) => (
  <div className="drag-handle-item">{children}</div>
));

const SortableItem = sortableElement(({ value, itemIndex }) => (
  <ListGroup.Item className="d-flex align-items-center justify-content-between">
    <DragHandleItem>
      <div className="d-flex align-items-center justify-content-between w-100">
        <span className="file-path">{`${itemIndex + 1}. ${value.path}`}</span>
        <Badge variant="secondary" className="ml-2">
          {value.size
            ? `${(value.size / 1024 / 1024).toFixed(2)} MB`
            : "Unknown size"}
        </Badge>
      </div>
    </DragHandleItem>
  </ListGroup.Item>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ListGroup className="sortable-container">{children}</ListGroup>;
});

const processFiles = (files) => {
  let fileParts = files.map((f) => {
    let fparts = f.path.split("/");
    fparts.____file = f.path;
    return fparts;
  });

  let tree = {};

  while (fileParts.filter((p) => p.length > 0).length > 0) {
    for (let i = 0; i < fileParts.length; i++) {
      var filePartsI = fileParts[i];
      let fpart = filePartsI.shift();

      var dir = tree;
      while (fpart) {
        dir[fpart] = dir[fpart] || {};
        dir[fpart].____file = filePartsI.____file;
        dir = dir[fpart];
        fpart = filePartsI.shift();
      }
    }
  }
  return tree;
};

var uploadedFiles = [];

const FileNode = (file, index) => {
  let children = Object.keys(file).filter((key) => key !== "____file");

  return (
    <ul key={index}>
      {children.map((c, key) => {
        let children = Object.keys(file[c]).filter((key) => key !== "____file");
        let isUploaded = uploadedFiles.indexOf(file[c].____file) > -1;
        if (children.length === 0) {
          // it is a file
          return isVideo(c) || isImage(c) ? (
            <li className="file video" key={key}>
              {c}
              {isUploaded && (
                <img src="check.png" alt="uploaded" className="checkmark" />
              )}
            </li>
          ) : (
            <li className="file not-video" key={key}>
              {c}
            </li>
          );
        } else {
          // it is a folder
          return (
            <li key={key}>
              <details open>
                <summary>
                  <b className="not-video">{c}</b>
                </summary>
                {FileNode(file[c])}
              </details>
            </li>
          );
        }
      })}
    </ul>
  );
};

class DropZone extends React.Component {
  constructor() {
    super();
    this.state = {
      uploadCompleted: false,
      uploadCancelled: false,
      files: [],
      acceptedFiles: [],
      surgeryFacility: "",
      surgeonName: "",
      procedureType: "",
      stitchVideosEnabled: true,
      disableVideoStitchToggle: false,
      videosStitched: false,
      stitchedVideoName: "",
      userChangedStitchSetting: false,
      isZipFilePresent: false,
      isZipFilesUploading: false,
      isZippedFilesUploaded: false,
      currentZipFileIndex: 0,
      totalZipFiles: 0,
    };
    this.onDrop = this.onDrop.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadAgain = this.uploadAgain.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
    this.uploadToAssetManager = this.uploadToAssetManager.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleToggleStitch = (event) => {
    this.setState({
      stitchVideosEnabled: event.target.checked,
      userChangedStitchSetting: true,
    });
  };

  arrayMoveMutable = (array, fromIndex, toIndex) => {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
    if (startIndex >= 0 && startIndex < array.length) {
      const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
      const [item] = array.splice(fromIndex, 1);
      array.splice(endIndex, 0, item);
    }
  };

  arrayMoveImmutable = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    this.arrayMoveMutable(newArray, fromIndex, toIndex);
    return newArray;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ files }) => ({
      files: this.arrayMoveImmutable(files, oldIndex, newIndex),
    }));
  };

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  renderVideoMetadataInput() {
    const {
      surgeryFacility,
      surgeonName,
      procedureType,
      stitchVideosEnabled,
      disableVideoStitchToggle,
    } = this.state;
    const s3BucketName = this.props.s3BucketName;
    const showError =
      s3BucketName === "sme-media" || s3BucketName === "sme-media-demo";

    return (
      <div className="video-metadata">
        <Form>
          <Form.Group className="mb-3" controlId="formSurgeryFacility">
            <Form.Label>Surgery Facility</Form.Label>
            <Form.Control
              type="text"
              name="surgeryFacility"
              placeholder="Enter surgery facility name"
              value={surgeryFacility}
              onChange={this.handleInputChange}
              isInvalid={showError && !surgeryFacility}
            />
            {showError && !surgeryFacility && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                Please enter the surgery facility.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSurgeonName">
            <Form.Label>Surgeon Name</Form.Label>
            <Form.Control
              type="text"
              name="surgeonName"
              placeholder="Enter surgeon name"
              value={surgeonName}
              onChange={this.handleInputChange}
              isInvalid={showError && !surgeonName}
            />
            {showError && !surgeonName && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                Please enter the surgeon name.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formProcedureType">
            <Form.Label>Procedure Type</Form.Label>
            <Form.Control
              type="text"
              name="procedureType"
              placeholder="Enter procedure type"
              value={procedureType}
              onChange={this.handleInputChange}
              isInvalid={showError && !procedureType}
            />
            {showError && !procedureType && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                Please enter the procedure type.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="stitch-toggle">
            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                id="stitch-toggle"
                label={
                  disableVideoStitchToggle
                    ? "Video stitching disabled because there are fewer than 2 video files"
                    : `Video Stitching ${
                        stitchVideosEnabled ? "Enabled" : "Disabled"
                      }`
                }
                checked={stitchVideosEnabled}
                onChange={this.handleToggleStitch}
                disabled={disableVideoStitchToggle}
              />
            </Form.Group>
          </div>
        </Form>
      </div>
    );
  }

  checkAndToggleStitching(files) {
    const videoFiles = files.filter((file) => isVideo(file.path));
    const hasMultipleVideos = videoFiles.length >= 2;

    if (hasMultipleVideos) {
      this.setState((prevState) => ({
        disableVideoStitchToggle: false,
        stitchVideosEnabled: prevState.userChangedStitchSetting
          ? prevState.stitchVideosEnabled
          : true,
      }));
    } else {
      this.setState({
        stitchVideosEnabled: false,
        disableVideoStitchToggle: true,
      });
    }
  }

  onDrop(files) {
    files.sort((a, b) =>
      a.name.localeCompare(
        b.name,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
          sensitivity: "base",
        }
      )
    );
    this.setState({ files });
    let videoFiles = files.filter((f) => isVideo(f.path));
    let imageFiles = files.filter((f) => isImage(f.path));
    let zipFiles = files.filter((f) => isZip(f.path));
    let CSVFiles = files.filter((f) => isCSV(f.path));
    let acceptedFiles = videoFiles
      .concat(imageFiles)
      .concat(zipFiles)
      .concat(CSVFiles);
    this.setState({ acceptedFiles });
    this.checkAndToggleStitching(files);

    if (zipFiles.length > 0 && this.props.onZipFileDropped) {
      this.props.onZipFileDropped();
    }
  }

  async upload() {
    var newFolderNameHash = {};

    // name batches with the timestamp
    let dateString = new Date().toISOString();
    // generate 3 random digits
    const randomDigits = Math.floor(100 + Math.random() * 900);
    // generate a random letter between A-Z, 65 is 'A'
    const randomUpperCaseLetter = String.fromCharCode(
      65 + Math.floor(Math.random() * 26)
    );

    const timestampPart = dateString.split(".")[1];
    const timestampWithoutZ = timestampPart.replace("Z", "");
    const finalTimestamp = `${timestampWithoutZ}${randomDigits}${randomUpperCaseLetter}`;
    dateString = `${dateString.split(".")[0]}.${finalTimestamp}`;

    let batchName = `${dateString.split(".")[0]}.${finalTimestamp}-batch`;
    let uploadBatch = `${this.props.user.email}/${batchName}`;
    let s3BucketName = this.props.s3BucketName;

    // progress bar variables
    this.totalBytesToTransfer = 0;
    this.totalBytesTransfered = 0;
    this.setState({ progress: 0, totalProgress: 0, baseProgress: 0 });
    this.totalBytesToTransfer = _.sumBy(this.state.acceptedFiles, "size");
    let s3UrisForVideoStitching = [];

    let userSortedFiles = this.state.files.filter(
      (f) =>
        isVideo(f.path) || isImage(f.path) || isZip(f.path) || isCSV(f.path)
    );

    let csvFiles = userSortedFiles.filter((f) => isCSV(f.path));
    let otherFiles = userSortedFiles.filter((f) => !isCSV(f.path));

    // Concatenate .csv files at the beginning
    userSortedFiles = [...csvFiles, ...otherFiles];

    userSortedFiles
      .reduce((promise, file, i) => {
        return promise
          .then(async () => {
            if (this.state.uploadCancelled) {
              return [];
            }

            this.setState({ currentFileNumber: 1 + i });

            /* Preserve the file's parent dir if it exists */
            const parentDir = getParentDir(file.path);
            const filename = getFilename(file, dateString, i);

            let filepath;

            if (parentDir) {
              /* Check to see if a new folder name exists */
              if (newFolderNameHash[parentDir]) {
                let dirName = newFolderNameHash[parentDir];
                filepath = `${uploadBatch}/${dirName}/${filename}`;
              } else {
                /* Preserve any key words in the folder name */
                const matches = parentDir.match(
                  /(knee|shoulder|hip|ct3d|ctreg)/g
                );
                const preserved_words = matches ? matches.join("_") : "";
                let newDirName = "folder_" + preserved_words + "_" + uuidv4();
                newFolderNameHash[parentDir] = newDirName;
                filepath = `${uploadBatch}/${newDirName}/${filename}`;
              }
            } else {
              filepath = `${uploadBatch}/${filename}`;
            }

            const url = await getPreSignedUrl(filepath, s3BucketName);

            const isVideoFile = (filepath) => {
              const videoExtensions = ["mp4", "mov", "avi", "mpeg", "mpg"];
              const filename = filepath.substring(
                filepath.lastIndexOf("/") + 1
              );
              const extension = filename.split(".").pop().toLowerCase();
              return videoExtensions.includes(extension);
            };

            const s3Url = `s3://${s3BucketName}/unprocessed-media/${filepath}`;

            // Only stitch video files, not image files
            if (isVideoFile(filepath)) {
              s3UrisForVideoStitching.push(s3Url);
            }

            let totalBytes;

            const updateProgress = (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              const deltaProgress = (progress - this.state.progress) / 100; //isnt this.state.progress == 0?
              const deltaBytes = deltaProgress * progressEvent.total;
              this.totalBytesTransfered += deltaBytes;
              this.setState({ progress });
              const totalProgress =
                progress * (progressEvent.total / this.totalBytesToTransfer) +
                this.state.baseProgress;
              this.setState({ totalProgress });
            };

            var config = {
              onUploadProgress: function (progressEvent) {
                totalBytes = progressEvent.total;
                updateProgress(progressEvent);
              },
            };

            let uploadTask = uploadFileToS3(url, file, config);
            uploadTask.then(async (_) => {
              uploadedFiles.push(file.path);
              this.setState({ progress: 0 }); //reset the state
              let currentBaseProgress = this.state.baseProgress;
              let progressForFile =
                (totalBytes / this.totalBytesToTransfer) * 100;
              this.setState({
                baseProgress: currentBaseProgress + progressForFile,
              });

              // If the file is a zip, unzip and upload contents
              if (isZip(file.path)) {
                this.setState({
                  isZipFilePresent: true,
                  isZipFilesUploading: true,
                });
                const zip = new JSZip();
                const zipContent = await zip.loadAsync(file);
                const zipEntries = Object.keys(zipContent.files).filter(
                  (entry) => entry.endsWith(".dcm")
                );

                this.setState({ totalZipFiles: zipEntries.length });

                // Group files by their parent folder
                const folders = zipEntries.reduce((acc, zipEntry) => {
                  const parts = zipEntry.split("/");
                  const folder = parts[0];
                  if (!acc[folder]) {
                    acc[folder] = [];
                  }
                  acc[folder].push(zipEntry);
                  return acc;
                }, {});

                const folderUploadTasks = Object.keys(folders).map(
                  async (folder) => {
                    const folderZip = new JSZip();
                    folders[folder].forEach((zipEntry) => {
                      const zipFile = zipContent.files[zipEntry];
                      if (zipFile.name.endsWith(".dcm") && !zipFile.dir) {
                        folderZip.file(zipEntry, zipFile.async("blob"));
                      }
                    });

                    if (Object.keys(folderZip.files).length > 0) {
                      const folderZipBlob = await folderZip.generateAsync({
                        type: "blob",
                      });

                      // Create a new batch name for each subfolder zip
                      let subfolderBatchName = `${dateString}-Study_${
                        Object.keys(folders).indexOf(folder) + 1
                      }`;

                      let subfolderUploadBatch = `${this.props.user.email}/${subfolderBatchName}`;

                      const folderZipFileName = `${subfolderUploadBatch}/${folder}.zip`;

                      const folderZipUrl = await getPreSignedUrl(
                        folderZipFileName,
                        s3BucketName
                      );

                      const folderZipUploadTask = uploadFileToS3(
                        folderZipUrl,
                        folderZipBlob,
                        config
                      );

                      folderZipUploadTask
                        .then(async (result) => {
                          const zipFileHash = await computeChecksumMd5(
                            folderZipBlob
                          );

                          this.uploadBlobToAssetManager(
                            result.config,
                            zipFileHash
                          );
                        })
                        .catch((error) => {
                          console.error("Error uploading folder zip:", error);
                        });

                      await folderZipUploadTask;

                      // Unzip and upload individual files
                      for (let zipEntry of folders[folder]) {
                        const zipFile = zipContent.files[zipEntry];
                        if (!zipFile.dir) {
                          const zipFileBlob = await zipFile.async("blob");

                          const zipFilePath = `${subfolderUploadBatch}/${zipEntry}`;
                          const zipFileUrl = await getPreSignedUrl(
                            zipFilePath,
                            s3BucketName
                          );

                          const zipFileUploadTask = uploadFileToS3(
                            zipFileUrl,
                            zipFileBlob,
                            config
                          );

                          await zipFileUploadTask;

                          this.setState((prevState) => ({
                            currentZipFileIndex:
                              prevState.currentZipFileIndex + 1,
                          }));
                        }
                      }
                    }
                  }
                );
                await Promise.all(folderUploadTasks);
                this.setState({
                  isZippedFilesUploaded: true,
                  isZipFilesUploading: false,
                });
              }
            });

            return uploadTask;
          })
          .then(async (res) => {
            const hashVal = await computeChecksumMd5(file);
            this.uploadToAssetManager(res.config, file, hashVal);
          });
      }, Promise.all([]))
      .then((_) => {
        if (
          this.state.stitchVideosEnabled &&
          s3UrisForVideoStitching.length > 1
        ) {
          stitchVideosInAWS(s3UrisForVideoStitching)
            .then((stitchedVideoUri) => {
              this.setState({ videosStitched: true });

              const stitchedVideoName =
                stitchedVideoUri.substring(
                  stitchedVideoUri.lastIndexOf("/") + 1
                ) + (stitchedVideoUri.endsWith(".mp4") ? "" : ".mp4");

              const payload = {
                uri: stitchedVideoUri + ".mp4",
                filename: stitchedVideoName,
                md5Hash: "StitchedVideoDummyHash",
                surgeryFacility: this.state.surgeryFacility,
                surgeonName: this.state.surgeonName,
                procedureType: this.state.procedureType,
              };

              this.setState({ stitchedVideoName: stitchedVideoName });
              uploadVideoToAssetManager(payload);
            })
            .catch((error) => {
              console.error("An error occurred while stitching videos:", error);
            });
        }
        window.clearInterval(ETACalculator);
        if (!this.state.uploadCancelled) {
          this.setState({
            secondsLeft: 0,
            progress: 100,
            totalProgress: 100,
            baseProgress: 100,
            uploadCompleted: true,
          });
        }
      });

    let startTime = Date.now();
    let ETACalculator = setInterval(() => {
      if (this.totalBytesTransfered > 0) {
        let secondsElapsed = (Date.now() - startTime) / 1000;
        let bytesPerSecond = this.totalBytesTransfered / secondsElapsed;
        let bytesLeft = this.totalBytesToTransfer - this.totalBytesTransfered;
        this.setState({ secondsLeft: bytesLeft / bytesPerSecond });
      }
    }, 1000);
  }

  uploadAgain() {
    window.location.reload();
  }

  cancelUpload() {
    this.setState({ uploadCancelled: true });
  }

  uploadToAssetManager = (metadata, file, hashVal) => {
    if (!isVideo(file.path) && !isZip(file.path) && !isCSV(file.path)) {
      return;
    }

    //Convert URL to URI
    const url = decodeURIComponent(metadata.url); //Decode URL encoding
    const regex = "\\S+\\/\\S+\\.\\S+(?=\\?)";
    const found = url.match(regex)[0];
    const uri = found.replace("https", "s3").replace(".s3.amazonaws.com", "");

    //Extract filename
    const filename = uri.replace(/^.*[\\\/]/, "");

    //Create payload for uploading to asset manager
    const payload = {
      uri: uri,
      filename: filename,
      md5Hash: hashVal,
      surgeryFacility: this.state.surgeryFacility,
      surgeonName: this.state.surgeonName,
      procedureType: this.state.procedureType,
    };

    uploadVideoToAssetManager(payload);
  };

  uploadBlobToAssetManager = (metadata, hashVal) => {
    //Convert URL to URI
    const url = decodeURIComponent(metadata.url); //Decode URL encoding
    const regex = "\\S+\\/\\S+\\.\\S+(?=\\?)";
    const found = url.match(regex)[0];
    const uri = found.replace("https", "s3").replace(".s3.amazonaws.com", "");

    //Extract filename
    const filename = uri.replace(/^.*[\\\/]/, "");

    //Create payload for uploading to asset manager
    const payload = {
      uri: uri,
      filename: filename,
      md5Hash: hashVal,
      surgeryFacility: this.state.surgeryFacility,
      surgeonName: this.state.surgeonName,
      procedureType: this.state.procedureType,
    };

    uploadVideoToAssetManager(payload);
  };

  renderCompleted = () => {
    if (
      !this.state.uploadCompleted ||
      (this.state.isZipFilePresent && !this.state.isZippedFilesUploaded)
    ) {
      return null;
    }
    return (
      <div className="feedbackSection">
        <Alert variant="success">
          <Alert.Heading>Thank you!</Alert.Heading>
          <p>All files have been uploaded securely.</p>
          {this.state.videosStitched && (
            <p>
              Videos have been stitched successfully.
              {this.state.stitchedVideoName &&
                ` The stitched video file is named "${this.state.stitchedVideoName}".`}
            </p>
          )}
          <h5>
            <Badge variant="secondary">
              {this.state.files.length} files selected
            </Badge>{" "}
            <Badge variant="danger">
              {this.state.files.length - this.state.acceptedFiles.length} files
              rejected for PII reasons
            </Badge>{" "}
            <Badge variant="success">
              {this.state.acceptedFiles.length} net files uploaded
            </Badge>
          </h5>
          <hr />
          <div className="d-flex justify-content-start">
            <Button onClick={this.uploadAgain} variant="outline-success">
              <i className="fas fa-redo"></i> Upload again
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  renderCancelled = () => {
    if (!this.state.uploadCancelled) {
      return null;
    }
    return (
      <div className="feedbackSection">
        <Alert variant="warning">
          <Alert.Heading>Upload cancelled</Alert.Heading>
          <p>Some files were not uploaded</p>
          <h5>
            <Badge variant="secondary">
              {this.state.files.length} files selected
            </Badge>{" "}
            <Badge variant="danger">
              {this.state.files.length - this.state.acceptedFiles.length} files
              rejected for PII reasons
            </Badge>{" "}
            <Badge variant="success">
              {uploadedFiles.length} net files uploaded
            </Badge>{" "}
            <Badge variant="warning">
              {this.state.acceptedFiles.length - uploadedFiles.length} files not
              uploaded
            </Badge>
          </h5>
          <hr />
          <div className="d-flex justify-content-start">
            <Button
              onClick={() => this.uploadAgain()}
              variant="outline-warning"
            >
              <i className="fas fa-redo"></i> Upload again
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  renderDropZone = (props) => {
    let className = "drop-zone";
    if (props.isDragActive) {
      className += " drag-hover ";
    }
    return (
      <div className={className} {...props.getRootProps()}>
        <input {...props.getInputProps()} />
        <div>
          <p className="text-muted">
            <i className="fas fa-cloud-upload-alt fa-3x"></i>
          </p>
          <p className="text-muted">Drag files/folder here to upload</p>
          <p className="text-muted">or</p>
          <Button variant="primary" className="choose-files-btn">
            Choose files/folder
          </Button>
        </div>
      </div>
    );
  };

  renderUploading = () => {
    if (
      !this.state.currentFileNumber ||
      (this.state.uploadCompleted &&
        this.state.isZipFilePresent &&
        this.state.isZippedFilesUploaded) ||
      this.state.uploadCancelled
    ) {
      return null;
    }
    const secondsLeft = _.get(this.state, "secondsLeft", 0);

    return (
      <div className="feedbackSection">
        <Alert variant="secondary">
          <Alert.Heading>
            Uploading net {this.state.currentFileNumber} /{" "}
            {this.state.acceptedFiles.length}
          </Alert.Heading>
          <ProgressBar
            striped
            variant="success"
            now={this.state.totalProgress}
          />
          {secondsLeft && !this.state.isZipFilesUploading ? (
            <p>
              Time remaining:
              {getHours(secondsLeft)}h {getMinutes(secondsLeft)}m
              {getSeconds(secondsLeft)}s
            </p>
          ) : (
            ""
          )}
          <p>
            {this.state.isZipFilePresent && !this.state.isZippedFilesUploaded
              ? `Unzipping files and uploading... (${this.state.currentZipFileIndex}/${this.state.totalZipFiles})`
              : ""}
          </p>
          <hr />
          <div className="d-flex justify-content-start">
            <Button onClick={this.cancelUpload} variant="outline-secondary">
              <i className="fas fa-times"></i> Cancel upload
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  renderUploadReady = (fileTree) => {
    if (!fileTree || this.state.currentFileNumber) {
      return null;
    }

    const {
      surgeryFacility,
      surgeonName,
      procedureType,
      files,
      acceptedFiles,
    } = this.state;

    const { s3BucketName } = this.props;

    const isDisabled =
      (s3BucketName === "sme-media" || s3BucketName === "sme-media-demo") &&
      (!surgeryFacility || !surgeonName || !procedureType);

    return (
      <div className="feedbackSection">
        <Alert variant="secondary">
          <Alert.Heading>Upload info</Alert.Heading>
          <h5>
            <Badge variant="secondary">{files.length} files selected</Badge>{" "}
            <Badge variant="danger">
              {files.length - acceptedFiles.length} files rejected for PII
              reasons
            </Badge>{" "}
            <Badge variant="success">
              {acceptedFiles.length} net files will be uploaded
            </Badge>
          </h5>
          <hr />
          <div className="d-flex justify-content-start">
            <Button
              onClick={this.upload}
              variant="outline-secondary"
              disabled={isDisabled}
            >
              <i className="fas fa-upload"></i> Upload
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  render() {
    const fileTree = _.isEmpty(this.state.files)
      ? null
      : processFiles(this.state.files);

    const showUploading =
      !this.state.uploadCompleted ||
      (this.state.isZipFilePresent && !this.state.isZippedFilesUploaded);

    return (
      <div className="parentSection">
        {showUploading && this.renderUploading()}
        {!showUploading && this.renderCompleted()}
        {this.renderCancelled()}
        {this.renderUploadReady(fileTree)}
        {this.renderVideoMetadataInput()}
        {!_.isEmpty(this.state.files) && (
          <>
            <p>
              <strong>Use your mouse to drag and sort</strong> the following
              files. Videos will be uploaded in the order you arrange them.
              {this.state.stitchVideosEnabled && (
                <span>
                  {" "}
                  A stitched video reflecting your sorting order will also be
                  uploaded after processing.
                </span>
              )}
            </p>
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
              {this.state.files.map((file, index) => (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  value={file}
                  itemIndex={index}
                />
              ))}
            </SortableContainer>
          </>
        )}
        {_.isEmpty(this.state.files) && (
          <RDropZone
            getDataTransferItems={(evt) => fromEvent(evt)}
            onDrop={this.onDrop}
          >
            {this.renderDropZone}
          </RDropZone>
        )}
      </div>
    );
  }
}

export default DropZone;
